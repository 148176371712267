<template>
  <v-card class="elevation-0">
    <div v-if="!loadingStatus" class="timeline timeline-5">
      <div class="timeline-items">
        <!--begin::Item-->
        <div class="timeline-item" v-for="(item, i) in actions" :key="i">
          <!--begin::Icon-->
          <div class="timeline-media bg-light-primary">
            <i
              v-b-tooltip.hover.right="$t('MF.changes.types.appointment')"
              v-if="item.type == '1'"
              class="flaticon2-calendar-2"
            ></i>
            <i
              v-b-tooltip.hover.right="$t('MF.changes.types.referral')"
              v-if="item.type == '2'"
              class="flaticon2-fast-next"
            ></i>
          </div>
          <!--end::Icon-->

          <!--begin::Info-->
          <div class="timeline-desc timeline-desc-light-primary">
            <span class="font-weight-bolder text-primary">{{
              item.created_on | moment("from", "now")
            }}</span>
            <p
              v-if="item.type == '1'"
              class="font-weight-normal text-dark-50 pb-2"
            >
              {{ $t("MF.updates.appointment") }} {{ item.owner.first_name }}
              {{ item.owner.last_name }}
            </p>
            <p
              v-if="item.type == '2'"
              class="font-weight-normal text-dark-50 pb-2"
            >
              {{ $t("MF.updates.consultation") }} {{ item.owner.first_name }}
              {{ item.owner.last_name }}
            </p>
            <a
              @click="
                via = item.id;
                summery = true;
              "
              class="btn btn-success"
            >
              <i class="flaticon2-medical-records"></i
              >{{ $t("MF.changes.summery") }}
            </a>
          </div>
          <!--end::Info-->
        </div>
        <!--end::Item-->
      </div>
    </div>
    <div
      v-else
      class="text-center mt-5 mb-5"
      style="width: 100%; margin-right: auto; margin-left: auto"
    >
      <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
    </div>

    <Summery
      :via="via"
      :dialog="summery"
      @closeClicked="closeSummery($event)"
    ></Summery>
  </v-card>
</template>

<script>
import ApiService from "@/core/services/api.service";
//import Avatar from "@/view/pages/utilComponents/avatar2.vue";
import Summery from "./summery.vue";

export default {
  name: "Changes",
  props: ["mfid"],
  components: { Summery },
  data() {
    return {
      via: null,
      summery: false,
      loadingStatus: true,
      actions: [],
      error: "",
      headers: [
        {
          text: this.$t("MF.by"),
          align: "left",
          sortable: true,
          value: "owner.first_name"
        },
        { text: this.$t("MF.changes.type"), value: "type" },
        { text: this.$t("MF.date"), value: "created_on" },
        {
          text: this.$t("MF.changes.summery"),
          value: "summery",
          align: "right"
        }
      ],
      lastSelected: null
    };
  },
  mounted() {
    this.getChanges();
  },
  methods: {
    async getChanges() {
      //var userInfo = JSON.parse(localStorage.userInfo).id;

      await ApiService.get(
        "items",
        "md_doctor_actions?fields=id,owner.id,owner.first_name,owner.last_name,owner.avatar.data,type,appointment,referral,created_on&filter[medical_file]=" +
          this.mfid+"&limit=20&sort=-created_on"
      )
        .then(({ data }) => {
          //console.log(data.data);
          this.actions = data.data;
          this.loadingStatus = false;
        })
        .catch(error => {
          this.error = error;
        });
    },
    closeSummery(d) {
      this.summery = d;
    }
  }
};
</script>
